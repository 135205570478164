import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpServiceService, AppService } from 'src/app/services';
import { CommonUtil } from 'src/app/util';

@Component({
  selector: 'app-booking-from-date',
  templateUrl: './booking-from-date.component.html',
  styleUrls: [
    './booking-from-date.component.css',
    '../../common-users-component/header/header.component.css',
  ],
})
export class BookingFromDateComponent implements OnInit {
  @Input() bookid: any;

  requests: any;
  dueDate: any;
  searchSub: any;
  constructor(
    public dialog: MatDialog,
    public _util: CommonUtil,
    private _appSer: AppService,
    private _: HttpServiceService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    const url = 'listClass/' + this.bookid;
    this._.getReqAuth(url).subscribe((res) => this.success(res));
  }

  success(res: any) {
    if (res.status == false) {
      if (res.code == 900101) {
      }
    } else {
      this.requests = res;
      if (this.requests.length) {
        this.dueDate = this.requests[0].teachers_dates.from_date;
      }
    }
  }
}
